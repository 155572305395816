<template>
  <div class="w-100">
    <div class="my-1 modal-branch">
      <b-button
        variant="select-branch"
        class="w-100 w-sm-unset text-nowrap"
        @click.prevent="show($event)"
      >
        {{ text }}
        <font-awesome-icon icon="chevron-right" class="ft-14 ml-2" />
      </b-button>
      <span class="ml-2 d-none d-md-inline-flex p-black">
        ({{ BranchSelectList.length }} Selected)
      </span>
    </div>
    <div>
      <b-modal
        v-model="showModal"
        ref="ModalSelectBranch"
        centered
        @hidden="hide"
        id="modal-branch"
      >
        <template #modal-header>Branch List</template>
        <b-form-group>
          <template #label>
            <b-form-checkbox
              v-model="selectAllBranch"
              @change="toggleAllBranch()"
              :disabled="
                valid == 1
                  ? allBranchCheck == allSelectBranchCheck
                    ? true
                    : false
                  : false
              "
              class="my-2"
            >
              All Branches
            </b-form-checkbox>
          </template>
          <b-tabs content-class="mt-3" v-if="!isCounting" ref="b_tabs" fill>
            <b-tab v-for="(item, index) in list" :key="index">
              <template v-slot:title>
                <div>
                  <span>{{ item.branch_group }}</span>
                  <span> ({{ item.selected_count }}) </span>
                </div>
              </template>
              <b-form-group>
                <template>
                  <b-form-checkbox
                    :ref="`all_${item.branch_group}`"
                    v-model="allSelected"
                    :key="item.branch_group"
                    :value="item.branch_group"
                    @change="toggleAll(item.branch_group, index)"
                    class="checkDisable"
                    :tabindex="index"
                    :disabled="
                      valid == 1
                        ? item.count == item.branch_true_count
                          ? true
                          : false
                        : false
                    "
                  >
                    {{ item.branch_group }} All Branches
                  </b-form-checkbox>
                </template>

                <b-form-checkbox-group v-model="selected" class="mt-3">
                  <b-row>
                    <b-col cols="6" v-for="(data, i) in item.branch" :key="i">
                      <b-form-checkbox
                        class="mb-2 checkDisable"
                        :value="data.id"
                        :key="data.id"
                        @change="onChange(data.id)"
                        :disabled="
                          valid == 1 ? (data.is_check ? true : false) : false
                        "
                      >
                        {{ data.branch_code }} {{ data.name }}
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-form-checkbox-group>
              </b-form-group>
            </b-tab>
          </b-tabs>
        </b-form-group>
        <template #modal-footer>
          <b-row>
            <b-col class="text-left">
              <b-button @click.prevent="showModal = false" class="border-btn"
                >Cancel</b-button
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button class="submit-btn" @click.prevent="acceptSelectBranch()"
                >Select {{ selected.length }} Branch</b-button
              >
            </b-col>
          </b-row>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    BranchSelectList: {
      required: false,
    },
    list: {
      required: true,
      type: Array,
    },
    text: {
      required: true,
      type: String,
    },
    id: {
      required: true,
      type: String,
    },
    valid: {
      required: false,
      type: Number,
    },
  },
  data() {
    return {
      showModal: false,
      selected: [],
      BranchSelected: [],
      allSelected: [],
      selectAllBranch: false,
      allBranch: [],
      branch: "",
      countBranch: [],
      allBranchCheck: 0,
      allSelectBranchCheck: 0,
      branchOld: [],
      lockSelected: [],
      isCounting: false,
      branchDelete: [],
      deleteBranch: [],
      tabIndex: 0,
      tabSelected: 0,
    };
  },
  mounted() {
    for (const participating of this.list) {
      participating.selected_count = 0;
      let branchSelectedCount = 0;
      for (const branch of participating.branch) {
        if (branch.is_check) {
          this.selected.push(branch.id);
          this.lockSelected.push(branch.id);
          branchSelectedCount = branchSelectedCount + 1;
        }
      }
    }
    for (let i in this.list) {
      for (let j in this.list[i].branch) {
        this.allBranchCheck =
          this.allBranchCheck + this.list[i].branch_true_count;
        this.allSelectBranchCheck =
          this.allSelectBranchCheck + this.list[i].count;
        if (this.list[i].branch[j].is_check == true) {
          this.branchDelete.push(this.list[i].branch[j].id);
        }
      }
    }

    this.isSelectAll();
    this.BranchSelected = [...this.selected];
  },

  created() {},
  computed: {
    countBranchSelected() {
      return this.BranchSelected.length;
    },
  },
  methods: {
    show() {
      for (const participating of this.list) {
        let countBranch = 0;
        for (const branch of participating.branch) {
          if (branch.is_check) {
            countBranch += 1;
          }
        }
        participating.count = countBranch;
        participating.selected_count = countBranch;
      }

      this.selected = [...this.BranchSelectList];
      // this.toggleAllBranch();
      this.showModal = true;
      this.isSelectAll();
    },
    hide() {
      this.selected = [];

      this.BranchSelected = [...this.BranchSelectList];
      this.showModal = false;
    },
    toggleAll(branchGroup, tabIndex) {
      const focusGroup = this.list.filter((el) => {
        return el.branch_group == branchGroup;
      })[0];
      const checkBoxEl = this.$refs[`all_${branchGroup}`][0];
      const b_tabs = this.$refs.b_tabs;
      if (checkBoxEl) {
        if (checkBoxEl.isChecked) {
          for (const branchToAdd of focusGroup.branch) {
            if (!this.selected.includes(branchToAdd.id)) {
              this.selected.push(branchToAdd.id);
            }
            if (b_tabs && b_tabs.currentTab == tabIndex) {
              if (
                branchToAdd.is_check &&
                this.deleteBranch.includes(branchToAdd.id)
              ) {
                this.deleteBranch.splice(
                  this.deleteBranch.indexOf(branchToAdd.id),
                  1
                );
              }
            }
          }
        } else if (b_tabs) {
          for (const branchToRm of focusGroup.branch) {
            if (this.selected.includes(branchToRm.id)) {
              if (b_tabs.currentTab == tabIndex) {
                if (this.valid === 0) {
                  this.selected.splice(this.selected.indexOf(branchToRm.id), 1);
                  if (
                    branchToRm.is_check &&
                    !this.deleteBranch.includes(branchToRm.id)
                  ) {
                    this.deleteBranch.push(branchToRm.id);
                  }
                } else {
                  this.selected.splice(this.selected.indexOf(branchToRm.id), 1);
                  if (!this.deleteBranch.includes(branchToRm.id)) {
                    this.deleteBranch.push(branchToRm.id);
                  }
                }
              }
            }
          }
        }
      }
      this.isSelectAll();
    },
    toggleAllBranch() {
      if (this.selectAllBranch) {
        for (const participating of this.list) {
          this.allSelected.push(participating.branch_group);
          for (const branch of participating.branch) {
            if (!this.selected.includes(branch.id)) {
              this.selected.push(branch.id);
            }
          }
        }
      } else {
        this.selected = [];
        this.allSelected = [];
      }
      this.isSelectAll();
    },
    isSelectAll() {
      let isCheckAllBranchCount = 0;
      for (const participating of this.list) {
        let branchSelectedCount = 0;
        for (const branch of participating.branch) {
          if (!participating.selected_count) participating.selected_count = 0;
          if (this.selected.includes(branch.id)) {
            branchSelectedCount = branchSelectedCount + 1;
          }
        }
        participating.selected_count = branchSelectedCount;

        if (branchSelectedCount == participating.branch.length) {
          if (!this.allSelected.includes(participating.branch_group)) {
            this.allSelected.push(participating.branch_group);
          }
          isCheckAllBranchCount = isCheckAllBranchCount + 1;
        } else if (this.allSelected.includes(participating.branch_group)) {
          this.allSelected.splice(
            this.allSelected.indexOf(participating.branch_group),
            1
          );
        }
      }
      if (isCheckAllBranchCount == this.list.length && this.list.length !== 0) {
        this.selectAllBranch = true;
      } else this.selectAllBranch = false;
      this.isCounting = false;
    },
    onChange(id) {
      this.isCounting = true;
      for (const branchToDeleteId of this.branchDelete) {
        if (
          !this.selected.includes(branchToDeleteId) &&
          !this.deleteBranch.includes(branchToDeleteId)
        ) {
          this.deleteBranch.push(branchToDeleteId);
        } else if (
          this.selected.includes(branchToDeleteId) &&
          this.deleteBranch.includes(branchToDeleteId)
        ) {
          this.deleteBranch.splice(
            this.deleteBranch.indexOf(branchToDeleteId),
            1
          );
        }
      }
      this.isSelectAll();
    },
    acceptSelectBranch() {
      this.BranchSelected = [...this.selected];
      this.BranchSelectList = [...this.selected];
      this.$emit("selectBranch", this.selected);
      this.$emit("deleteBranch", this.deleteBranch);
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.ft-weight {
  font-weight: 600;
}

::v-deep .nav-tabs .nav-link:not(.active):hover {
  background-color: transparent;
  color: var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
}
::v-deep .modal-footer {
  justify-content: space-between;
}
::v-deep a {
  color: #707070 !important;
}

::v-deep .modal-dialog {
  @media (min-width: 576px) {
    max-width: 800px;
  }
}
.btn-cancel {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.pointer {
  cursor: pointer;
}
.confirm-btn:disabled {
  background-color: var(--font-disable-color) !important;
  border-color: var(--font-disable-color);
}
</style>
